<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card dense>
          <v-toolbar color="deep-purple lighten-2" dark flat>
            <v-toolbar-title>Produkt bearbeiten</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ProductForm />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ProductForm from "@/components/products/ProductForm";

export default {
  name: "ProductEdit",
  components: { ProductForm },
  data: () => ({}),

  methods: {},
  computed: {}
};
</script>
<style scoped></style>
