<template>
  <div class="productunit">
    <v-combobox
      label="Einheit"
      name="unit"
      type="text"
      :color="color"
      :items="units"
      :value="value"
      :required="required"
      :dense="dense"
      :rules="rules"
      :outlined="outlined"
      @change="updateValue"
    />
  </div>
</template>

<script>
export default {
  name: "ProductUntit",
  data: () => ({
    units: [
      "Keine",
      "Einsatz",
      "Flasche(n)",
      "Jahr(e)",
      "Monat(e)",
      "Tag(e)",
      "Karton",
      "Liter",
      "Kiste(n)",
      "Meter",
      "Nacht",
      "Tag",
      "Packung(en)",
      "Sack",
      "Tonnen",
      "kg",
      "lfm",
      "m²",
      "m³",
      "pauschal",
      "Kübel",
      "Stk",
      "Stunde(n)",
      "Minute(n)"
    ]
  }),
  props: {
    color: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      required: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      required: true
    }
  },
  //props: ["color", "value", "required", "rules", "dense", "outlined"],
  methods: {
    updateValue(value) {
      this.selected = value;
      this.$emit("input", this.selected);
    }
  },
  computed: {}
};
</script>

<style scoped></style>
