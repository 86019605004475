<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12">
        <v-card dense>
          <v-card-text>
            <v-form
              name="productform"
              @submit.prevent="save"
              v-model="formValid"
            >
              <v-container>
                <h2>Produktdetails</h2>

                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Produktnr"
                      name="productnr"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="product.productnr"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <MandantenSelectbox
                      color="purple"
                      v-model="product.mandant"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="8">
                    <v-text-field
                      label="Name"
                      name="name"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="product.name"
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="4">
                    <ProductUnit
                      dense
                      v-model="product.unit"
                      color="purple"
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12">
                    <v-textarea
                      label="Beschreibung"
                      name="description"
                      color="purple"
                      counter="80"
                      :outlined="true"
                      :clearable="true"
                      v-model="product.description"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Produkt Link"
                      v-model="product.productlink"
                      name="productlink"
                      placeholder="www.productlink.example"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="2">
                    <v-currency-field
                      label="EK"
                      prefix="€"
                      name="purchasing_price"
                      type="text"
                      color="purple"
                      v-model="product.purchasing_price"
                      required
                      dense
                      :rules="requiredRule"
                      @change="calcPrice"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field
                      label="Aufschlag in %"
                      name="surcharge"
                      type="text"
                      color="purple"
                      v-model="product.surcharge"
                      required
                      dense
                      :rules="numberRule"
                      @change="calcPrice"
                    />
                  </v-col>

                  <v-col cols="12" xs="12" sm="2">
                    <v-currency-field
                      label="Preis Netto"
                      prefix="€"
                      name="netto"
                      type="text"
                      color="purple"
                      v-model="product.netto"
                      required
                      dense
                      :rules="requiredRule"
                      @change="calcBrutto"
                      @blur="calcSurcharge"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="2">
                    <v-currency-field
                      label="Ust. in %"
                      name="ust"
                      type="text"
                      color="purple"
                      v-model="product.ust"
                      required
                      dense
                      :rules="requiredRule"
                      @change="calcBrutto"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="2">
                    <v-currency-field
                      label="Preis Brutto"
                      prefix="€"
                      name="brutto"
                      type="text"
                      color="purple"
                      v-model="product.brutto"
                      dense
                      :rules="requiredRule"
                      @change="calcNetto"
                    />
                  </v-col>
                </v-row>

                <v-divider />
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ path: '/products' }"
              color="error"
              type="button"
              name="cancel"
              >Abbrechen</v-btn
            >
            <v-spacer />
            <v-btn
              @click="save"
              color="success"
              name="submit"
              type="submit"
              :disabled="!formValid"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataService from "@/services/ProductService.js";
import MandantenSelectbox from "@/components/clients/MandantenSelectbox";
import ProductUnit from "@/components/products/ProductUnit";

export default {
  name: "productform",
  components: { MandantenSelectbox, ProductUnit },
  data: () => ({
    product: {
      _id: null,
      productnr: "",
      name: "",
      description: "",
      unit: "Stk",
      netto: 0,
      ust: 20,
      brutto: 0,
      surcharge: 20,
      purchasing_price: 0,
      categories: [],
      suppliers: [],
      productlink: "",
      image: null,
      mandant: "all",
      createdBy: "",
      createdTS: "",
      modifiedBy: "",
      modifiedTS: ""
    },

    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    numberRule: [
      (value) =>
        !!Number(value) || Number(value) === 0 || "Der Wert ist keine Zahl"
    ],
    formValid: true
  }),

  methods: {
    save() {
      DataService.save({
        id: this.product._id,
        data: this.product
      })
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "products" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Produkt gespeichert",
            text: "Produkt erfolgreich gespeichert"
          });
        })
        .catch((error) => {
          //on error alert error
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message
            });
          }
        });
    },

    formatNumber(e) {
      e.target.value = Number(e.target.value).toFixed(2);
    },

    calcPrice() {
      this.product.netto = Number(
        (Number.parseFloat(this.product.purchasing_price) *
          (Number.parseInt(this.product.surcharge) + 100)) /
          100
      ).toFixed(2);
      /*if (this.product.netto instanceof Number == false) {
        this.product.netto = 0;
      }*/
      this.calcBrutto();
    },
    calcSurcharge() {
      let surcharge = Number.parseInt(
        (Number.parseFloat(this.product.netto) /
          Number.parseFloat(this.product.purchasing_price)) *
          100 -
          100
      );
      /*if (surcharge instanceof Number == false) {
        surcharge = 0;
      }*/
      this.product.surcharge = surcharge;
    },
    calcBrutto() {
      //this.calcSurcharge();
      if (this.product.purchasing_price == 0) {
        this.product.purchasing_price = this.product.netto;
      }
      this.product.brutto = Number(
        (Number.parseFloat(this.product.netto) *
          (Number.parseInt(this.product.ust) + 100)) /
          100
      ).toFixed(2);
    },
    calcNetto() {
      this.product.netto = Number(
        (Number.parseFloat(this.product.brutto) * 100) /
          (Number.parseInt(this.product.ust) + 100)
      ).toFixed(2);
      if (this.product.purchasing_price == 0) {
        this.product.purchasing_price = this.product.netto;
      }
      this.calcSurcharge();
    }
  },
  computed: {},
  created() {},
  beforeCreate() {
    let id = this.$route.params.id;

    if (id) {
      DataService.get(id)
        .then((item) => {
          this.product = item.data.data;
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message
            });
          }
        });
    } else {
      this.$store.dispatch("product/getLastID").then((result) => {
        if (result) {
          let produktnr = Number.parseInt(result);
          produktnr++;
          this.product.productnr = produktnr;
        }
      });
    }
  }
};
</script>
